import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
  padding: 0.25rem 0;
`;

const SubList = styled.ul`
  padding-left: 1.5rem;
`;

const Heading = styled.h2`
  margin: 1rem 0 0.5rem;
`;

const SubHeading = styled.h3`
  margin: 0.5rem 0;
`;

const Paragraph = styled.p`
  margin: 0.5rem 0;
`;

const TermsAndConditions = () => {

    const companyName = process.env.COMPANY_NAME || "paycheck"
    const companyEmail = process.env.COMPANY_EMAIL || "info@paycheck.co.za"
    const productName = process.env.PRODUCT_NAME || "PayCheck"
    const companyNumber = process.env.COMPANY_CONTACT_NUMBER || "+27 87 654 7686"
    const compRegNum = process.env.COMPANY_REG_NUM || "n/a"
    const compAdd = process.env.COMPANY_ADDRESS || "n/a"
    const compWebsite = process.env.COMPANY_WEBSITE || "n/a"
    


  return (
    <div>
      <ol>
        <ListItem>
          <Heading>Introduction</Heading>
          <Paragraph>
            Thank you for selecting <strong>{productName}</strong> as your Earned Wage Access solution. This document details the terms and conditions that govern your use of the <strong>{productName}</strong> platform. By accessing and using the platform, you acknowledge and agree to be bound by these terms and conditions.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Purpose and Overview</Heading>
          <Paragraph>
            Your employer recognizes that employees may face financial emergencies requiring early access to a portion of their earned salary or wage before their regular payday. To support this, your employer provides the <strong>{productName}</strong> platform as an Earned Wage Access solution, enabling early access to your earned wages. By using this service, you acknowledge and agree that receiving an early payment at a discounted nominal value temporarily alters your employment conditions, specifically regarding the agreed next pay date and amount, with your employer acting as a party to the transaction under the terms outlined in this document.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Definitions and General Information</Heading>

          <SubHeading>3.1 Definitions</SubHeading>
          <SubList>
            <ListItem>
              We/Us/Company: <strong>{companyName}</strong> (Pty) Ltd.
            </ListItem>
            <ListItem>
              You/Your/User: The individual engaging with the service.
            </ListItem>
            <ListItem>
              Employer: The entity facilitating employee access to <strong>{productName}</strong>.
            </ListItem>
            <ListItem>
              <strong>{productName}:</strong> The Earned Wage Access (EWA) solution provided by {companyName} (Pty) Ltd, allowing users to access a portion of their already earned salary or wage prior to their regular payday.
            </ListItem>
            <ListItem>
              Earned Wage Access (EWA): A FinTech solution that allows employees to access a portion of their already earned salary or wage before their regular payday.
            </ListItem>
            <ListItem>
              Transaction Type: Accessing EWA through <strong>{productName}</strong> constitutes a discount transaction, where the user agrees to assign to the Company the right to collect the advanced amount, including any agreed-upon charges, from their employer.
            </ListItem>
          </SubList>

          <SubHeading>3.2 Company and Product Information</SubHeading>
          <SubList>
            <ListItem>
              Product Name: <strong>{productName}</strong>
            </ListItem>
            <ListItem>
              Company Name: <strong>{companyName}</strong> (Pty) Ltd
            </ListItem>
            <ListItem>
              Registration Number: <strong>{compRegNum}</strong>
            </ListItem>
            <ListItem>
              Physical Address: <strong>{compAdd}</strong>
            </ListItem>
            <ListItem>
              Telephone: <strong>{companyNumber}</strong>
            </ListItem>
            <ListItem>
              Email Address: <strong>{companyEmail}</strong>
            </ListItem>
            <ListItem>
              Website: <strong>{compWebsite}</strong>
            </ListItem>
          </SubList>
        </ListItem>

        <ListItem>
          <Heading>User Obligations and Agreements</Heading>

          <SubHeading>4.1 Discount Nature of Transactions</SubHeading>
          <Paragraph>
            By submitting a request, through any of the access points i.e. USSD or Web, to access a portion of your earned salary or wages before the traditional monthly payment date and receiving the funds, you agree to a discount transaction whereby you assign to us the right to collect the specified amount from your employer. This amount includes the portion advanced to you plus any agreed-upon factoring charges, which may comprise a percentage-based discount value and/or a fixed discount value, as detailed during your application process. Until your employer deducts and remits the agreed amount to us, the total amount will be considered owed by you to us.
          </Paragraph>

          <SubHeading>4.2 Consent Requirements</SubHeading>
          <Paragraph>
            Acknowledgment of these terms is required to proceed. Consent may be reused for future applications or product updates, including the processing of your personal information as outlined in Clause 6.1.
          </Paragraph>

          <SubHeading>4.3 Non-Cession of Rights</SubHeading>
          <Paragraph>
            Save as is specifically provided for in these terms and conditions, the employee shall not be entitled to cede any of its rights or delegate any of its obligations under these terms and conditions without the prior written consent of the Company.
          </Paragraph>
        </ListItem>

        <ListItem>
          <Heading>Employer and Payment Terms</Heading>

          <SubHeading>5.1 Employer Rights and Responsibilities</SubHeading>
          <SubList>
            <ListItem>
              Your employer has the discretion to provide or withdraw access to the <strong>{productName}</strong> service at any time. <strong>{companyName}</strong> (Pty) Ltd acts solely on the instructions of your employer regarding the implementation, management, or cancellation of the service.
            </ListItem>
            <ListItem>
              Employers can prescribe or limit payments facilitated through <strong>{productName}</strong> based on their policies. They also have the right to deduct the advanced amount, which constitutes a discount transaction, along with any applicable charges, from your next or final remuneration as instructed by the Company.
            </ListItem>
          </SubList>

          <SubHeading>5.2 Payment Handling</SubHeading>
          <SubList>
            <ListItem>
              Payments will only be made to the Bank Account or Wallet (Cell Phone Number) into which your salary is paid by your employer, as verified and supplied by your employer’s authorized individual.
            </ListItem>
            <ListItem>
              Employers can deduct repayments, including applicable charges, from the user's next or final remuneration.
            </ListItem>
            <ListItem>
              No third-party payments will be processed by the Company.
            </ListItem>
          </SubList>
          <SubHeading>5.3 Outstanding Balances</SubHeading>
            <Paragraph>
            If full repayment isn't collected, the balance may be converted to an unsecured loan per National Credit Act (NCA) guidelines, payable within 30 days at the discretion of the Company.
            </Paragraph>

        <SubHeading>5.4 Final Payment Recovery</SubHeading>
            <Paragraph>
            You hereby consent that any outstanding amount due to the Company will be deducted from your final payment upon termination of your employment with your employer.
            </Paragraph>
        </ListItem>

        <ListItem>
  <Heading>Data Handling and Legal Compliance</Heading>

  <SubHeading>6.1 Personal Information Handling</SubHeading>
  <Paragraph>
    In order to render the services, we require certain personal information, including but not limited to:
  </Paragraph>
  <SubList>
    <ListItem>Employment particulars, contact details, remuneration details, and banking details.</ListItem>
    <ListItem>
      Your information may also be used for:
      <SubList>
        <ListItem>Determining your eligibility via credit bureau checks (e.g., Experian).</ListItem>
        <ListItem>Sharing with third-party providers relevant to the services offered by the Company.</ListItem>
        <ListItem>Marketing campaigns based on transaction data.</ListItem>
      </SubList>
    </ListItem>
  </SubList>
  <Paragraph>
    All personal information will be processed in strict accordance with POPIA and the Electronic Communications and Transactions Act (ECTA). For more information, please refer to our Privacy Policy, available on our website.
  </Paragraph>

  <SubHeading>6.2 Compliance with South African Legal Requirements</SubHeading>
  <Paragraph>
    The Company operates in full compliance with South African legal requirements, ensuring that all services, transactions, and data handling are conducted within the parameters of the law. Specifically:
  </Paragraph>
  <SubList>
    <ListItem>
      Protection of Personal Information Act (POPIA): Ensures lawful and transparent processing, storage, and use of personal data. This includes implementing security measures to prevent unauthorized access to or misuse of personal information.
    </ListItem>
    <ListItem>
      Electronic Communications and Transactions Act (ECTA): Guarantees the validity, enforceability, and security of electronic transactions conducted on the <strong>{productName}</strong> platform. All digital agreements and communications are legally binding under ECTA.
    </ListItem>
    <ListItem>
      National Credit Act (NCA): Compliance with regulations governing unsecured loans, including proper disclosure of charges and interest rates applied to unpaid balances, ensuring fair and lawful credit practices.
    </ListItem>
    <ListItem>
      Basic Conditions of Employment Act (BCEA): Aligns EWA services with employment laws, ensuring no contravention of labor rights. Temporary changes in payment timing due to EWA services are implemented with the employer's and your consent to maintain compliance.
    </ListItem>
  </SubList>
</ListItem>

<ListItem>
  <Heading>Requests and Disputes</Heading>

  <SubHeading>7.1 Discretion for Granting Requests</SubHeading>
  <Paragraph>
    The granting of any request rendered by you to us to access any portion of your already earned salary or wage prior to the traditional monthly payment date is within the ambit of our sole, absolute, and unfettered discretion.
  </Paragraph>

  <SubHeading>7.2 Right to Decline Requests</SubHeading>
  <Paragraph>
    We reserve the right to enquire as to why you require access to your already earned salary or wage prior to the traditional monthly payment date and may decline any request without being required to advance any reasons, therefore.
  </Paragraph>

  <SubHeading>7.3 Dispute Resolution</SubHeading>
  <Paragraph>
    You have the right to dispute any transaction. However, you can only raise a dispute after the full amount outstanding has been paid by your employer.
  </Paragraph>
</ListItem>
<ListItem>
  <Heading>Section 43 Compliance</Heading>
  <SubList>
    <ListItem>
      The full name of the company is <strong>{companyName}</strong> (Pty) Ltd, and it operates as a private company in business.
    </ListItem>
    <ListItem>
      The physical address, website address, email address, and telephone number of the company is provided in Clause 3.2.
    </ListItem>
    <ListItem>The company does not belong to any self-regulatory or accreditation bodies.</ListItem>
    <ListItem>The company does not subscribe to any specific code of conduct.</ListItem>
    <ListItem>
      The company’s registration details can be found in Clause 3.2. The company does not have named office bearers.
    </ListItem>
    <ListItem>
      The address for receiving legal service of documents is provided in Clause 3.2.
    </ListItem>
    <ListItem>The services offered are described in Clause 2 and throughout these Terms.</ListItem>
    <ListItem>
      Pricing details are transaction specific and quoted during the application process but a broad guideline can be found in Clause 4.1 and Clause 5.2.
    </ListItem>
    <ListItem>Payment methods are outlined in Clause 5.2.</ListItem>
    <ListItem>No guarantees are offered under these Terms.</ListItem>
    <ListItem>Delivery timeframes are specified in employer agreements.</ListItem>
    <ListItem>Contact the company directly for transaction records.</ListItem>
    <ListItem>No refunds, exchanges, or returns except as stated in the Terms.</ListItem>
    <ListItem>No alternative dispute resolution codes apply.</ListItem>
    <ListItem>Security and privacy measures are in Clause 6.1 and the Privacy Policy.</ListItem>
    <ListItem>The company does not offer recurrent service agreements.</ListItem>
  </SubList>
</ListItem>
<ListItem>
  <Heading>Legal Terms</Heading>

  <SubHeading>9.1 Entire Agreement</SubHeading>
  <Paragraph>
    These terms constitute the entire agreement between the Company and the user.
  </Paragraph>

  <SubHeading>9.2 Changes to Terms</SubHeading>
  <Paragraph>
    We reserve the rights to modify these Terms from time to time. Changes will be posted on our website.
  </Paragraph>

  <SubHeading>9.3 Validity of Provisions</SubHeading>
  <Paragraph>
    If any provision is void, the remaining provisions shall remain valid.
  </Paragraph>

  <SubHeading>9.4 Governing Law</SubHeading>
  <Paragraph>
    These terms and conditions are governed by the laws of South Africa.
  </Paragraph>
</ListItem>
<ListItem>
  <Heading>Contact Information</Heading>
  <Paragraph>Details can be found in Clause 3.2.</Paragraph>
</ListItem>


      </ol>
    </div>
  );
};

export default TermsAndConditions;
