import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
  padding: 0.25rem 0;
`;

const SubList = styled.ul`
  padding-left: 1.5rem;
`;

const TableOfContents = () => {
  return (
    <ol>
      <ListItem key={1}>Introduction</ListItem>
      <ListItem key={2}>Purpose and Overview</ListItem>
      <ListItem key={3}>Definitions and General Information</ListItem>
      <ListItem key={4}>
        User Obligations and Agreements
        <SubList>
          <ListItem key="4.1">4.1 Discount Nature of Transactions</ListItem>
          <ListItem key="4.2">4.2 Consent Requirements</ListItem>
          <ListItem key="4.3">4.3 Non-Cession of Rights</ListItem>
        </SubList>
      </ListItem>
      <ListItem key={5}>
        Employer and Payment Terms
        <SubList>
          <ListItem key="5.1">5.1 Employer Rights and Responsibilities</ListItem>
          <ListItem key="5.2">5.2 Payment Handling</ListItem>
          <ListItem key="5.3">5.3 Outstanding Balances</ListItem>
          <ListItem key="5.4">5.4 Final Payment Recovery</ListItem>
        </SubList>
      </ListItem>
      <ListItem key={6}>
        Data Handling and Legal Compliance
        <SubList>
          <ListItem key="6.1">6.1 Personal Information Handling</ListItem>
          <ListItem key="6.2">6.2 Compliance with South African Legal Requirements</ListItem>
        </SubList>
      </ListItem>
      <ListItem key={7}>
        Requests and Disputes
        <SubList>
          <ListItem key="7.1">7.1 Discretion for Granting Requests</ListItem>
          <ListItem key="7.2">7.2 Right to Decline Requests</ListItem>
          <ListItem key="7.3">7.3 Dispute Resolution</ListItem>
        </SubList>
      </ListItem>
      <ListItem key={8}>Section 43 Compliance</ListItem>
      <ListItem key={9}>
        Legal Terms
        <SubList>
          <ListItem key="9.1">9.1 Entire Agreement</ListItem>
          <ListItem key="9.2">9.2 Changes to Terms</ListItem>
          <ListItem key="9.3">9.3 Validity of Provisions</ListItem>
          <ListItem key="9.4">9.4 Governing Law</ListItem>
        </SubList>
      </ListItem>
      <ListItem key={10}>Contact Information</ListItem>
    </ol>
  );
};

export default TableOfContents;
